@font-face {
    font-family: 'Hurme Geometric';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local('MyFont'), url(./assets/fonts/HurmeGeometricSans3-Regular.woff) format('woff');
}

@font-face {
    font-family: 'Hurme Geometric';
    font-style: normal;
    font-display: swap;
    font-weight: bold;
    src: local('MyFont'), url(./assets/fonts/HurmeGeometricSans3-Bold.woff) format('woff');
}

@font-face {
    font-family: 'Hurme Geometric';
    font-style: normal;
    font-display: swap;
    font-weight: bolder;
    src: local('MyFont'), url(./assets/fonts/HurmeGeometricSans3-BlackObl.woff) format('woff');
}

@font-face {
    font-family: 'Hurme Geometric';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('HurmeGeometricSans3'),
        local('HurmeGeometricSans3-Light'),
        url(./assets/fonts/HurmeGeometricSans3-Light.woff) format('woff');
}

* {
    font-family: "Hurme Geometric", sans-serif;
}

html {
    scroll-behavior: smooth;
}

body {
    background-color: #f4f7fd;
}

.nav-bar a {
    overflow: hidden;
    position: relative;
    display: inline-block;
}

.nav-bar a::before,
.nav-bar a::after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
}

.nav-bar a::before {
    background-color: #54b3d6;
    height: 2px;
    bottom: 0;
    transform-origin: 100% 50%;
    transform: scaleX(0);
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.nav-bar a::after {
    content: attr(data-replace);
    height: 100%;
    top: 0;
    transform-origin: 100% 50%;
    transform: translate3d(200%, 0, 0);
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
    color: #54b3d6;
}

.nav-bar a:hover::before {
    transform-origin: 0% 50%;
    transform: scaleX(1);
}

.nav-bar a:hover::after {
    transform: translate3d(0, 0, 0);
}

.nav-bar a span {
    display: inline-block;
    transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
}

.nav-bar a:hover span {
    transform: translate3d(-200%, 0, 0);
}


.css-mhc70k-MuiGrid-root>.MuiGrid-item.sin-pading {
    padding-top: 0
}

.css-197t7h2-MuiTypography-root.font-famyli-roman-letters {
    font-family: 'Lora', serif;
    font-weight: 800;

}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -5px !important;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    background-color: rgb(13, 0, 86) !important;
}



@keyframes float {
    0% {
        /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
        transform: translatey(0px);
    }

    50% {
        /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
        transform: translatey(-20px);
    }

    100% {
        /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
        transform: translatey(0px);
    }
}

.floating-continer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.floating-avatar {
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
    position: relative;
    z-index: 10;

}

.floating-avatar img {
    width: 80%;
    height: auto;
}

.about .css-jf75l1-MuiGrid-root>.MuiGrid-item {
    padding: 0
}

.nfriend {
    height: 0;
    transition: all .3s ease-in-out;
    opacity: 0;
}

.open {
    animation-name: openNfriends;
    animation-duration: .5s;
    animation-fill-mode: forwards;
}

.closed {
    height: 0;
    animation-name: closeNfriends;
    animation-duration: .5s;
    animation-fill-mode: backwards;
}

@keyframes openNfriends {
    0% {
        height: 0;
        opacity: 0;
    }

    50% {
        height: 50%;
        opacity: .3;
    }

    100% {
        height: 100%;
        opacity: 1
    }
}

@keyframes closeNfriends {
    0% {
        height: 100%;
        opacity: 1;
    }

    50% {
        height: 50%;
        opacity: .5;
    }

    100% {
        height: 0px;
        opacity: 0
    }
}


/*  Input Formulario =================================================================================================== */

.auth-login .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-weight: 500;
}

.PhoneInputInput {
    gap: 4px;
}

.PhoneInputCountrySelect {
    font-weight: 500;
}

.PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    font-weight: 700;
    font-size: 15px;
    opacity: 0;
    cursor: pointer;
    font-family: 'Hurme Geometric';
}

.auth-login .PhoneInputInput {
    font-weight: 500;
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 16.5px 14px;
    height: 23px;
    border-color: rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
}

.auth-login .PhoneInputInput:focus,
.auth-login .PhoneInputInput:active {
    border: 1px solid #13017b !important;
}


/*  Input Formulario =================================================================================================== */



.about-nfriend.css-1xkwoyv-MuiGrid-root>.MuiGrid-item {
    padding: 0;
}


/* BUY NFT CARD ANIMATION */


.NFT-image-type {
    position: absolute;
    top: 0;
    left: 0;
}

.NFT-image-type.show {
    visibility: visible;
}

.NFT-image-type.hidden {
    visibility: hidden;
}

.banner-tikect-image {
    opacity: 0;
}

.slider {
    height: 100%;
    transition: all .5s;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
}


.banner-publicitario span {
    color: #00b8ff ;
    font-weight: 800;
}

.gelatine-animation {
    animation: gelatine 0.5s ;
    animation-iteration-count: infinite;
}

 @keyframes gelatine {
   0% {
     -webkit-transform: scale(1, 1);
     transform: scale(1, 1);
   }
   25% {
     -webkit-transform: scale(0.9, 1.1);
     transform: scale(0.9, 1.1);
   }
   50% {
     -webkit-transform: scale(1.1, 0.9);
     transform: scale(1.1, 0.9);
   }
   75% {
     -webkit-transform: scale(0.95, 1.05);
     transform: scale(0.95, 1.05);
   }
 }

 .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}
.embed-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (min-width: 600px) {
    .app-nfriend__tostify-success-footer {
        width: 500px;
        right: 12em;
    }
}
@media (max-width: 690px) {
    .img-floating-banner-publicitario {
        display: none;
    }
}

.mostrar-banner-life-event {
    right: 1%;
    bottom: 1%;
}
.ocultar-banner-life-event {
    right: -100%;
    bottom: 1%;
}