.stats-container {
  width: 180px;
  height: 150px;
  overflow: hidden;
  position: relative;
  margin: 5px auto;
}

.stats-wrap {
  background-color: transparent;
  position: relative;
  transform: translateY(-20%);
  top: 30%;
  margin-left: 5px;
  width: 8px;
  height: 150px;
  float: left;
}

.stats-bar {
  background-color: lightgray;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90%;
  box-sizing: border-box;
  border-radius: 2px 2px 0 0;
  animation: grow 1.5s ease-out forwards;
  transform-origin: bottom;
}

.icon-wrapper {
  position: relative;
  top: 30%;
  right: 8px;
  transform: translateY(-100%);
}

.icon {
  animation: slidein 1s linear backwards;
  position: absolute;
  transform-origin: bottom;
}


@keyframes grow {
  from {
    transform: scaleY(0);
  }
}

@keyframes slidein {
  from {
    transform: translateY(200%);
  }

  to {
    transform: translateY(0);
  }

}