.square-holder {
    padding: 30px;
    border: 1px solid #cecece;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    background-color: #f1f1f1;
    min-height: 200px;
    cursor: pointer;

    img {
        width: 100%;
        max-width: 200px;
        filter: grayscale(100%);
        transition: all 0.3s;
    }

    &:hover img {
        filter: none;
    }
}

.clock-subasta {
    position: relative;
    font-weight: 800;

    &::after {
        content: '';
        display: block;
        position: absolute;
        font-size: 12px;
        bottom: -10px;
        left: 46%;
        transform: translateX(-50%);
        font-weight: 500;
    }

    &.day::after {
        content: 'day';
    }

    &.hrs::after {
        content: 'hrs';
    }

    &.min::after {
        content: 'min';
    }

    &.sec::after {
        content: 'sec';
    }
}

.text-editor {
    transition: height 1s linear;
    height: 0px;
    visibility: hidden;

    &.show-text-editor {
        height: auto;
        visibility: visible;
    }

    &.hidden-text-editor {
        height: 0;
        visibility: hidden;
    }
}


.exclusive-subasta {
    position: relative;
    overflow: hidden;

    &.exclusive-subasta::after {
        content: 'Unique';
        display: flex;
        justify-content: center;
        align-items: 'center';
        font-weight: 800;
        color: #fff;
        position: absolute;
        font-size: 26px;
        text-transform: uppercase;
        top: 10%;
        left: 25%;
        transform: rotateZ(45deg);
        width: 100%;
        background: #00b8ff;

        @media (min-width: 1450px) {
            top: 12%;
            left: 30%;
            font-size: 28px;
        }

        @media (max-width: 350px) {
            top: 5%;
            left: 14%;
            font-size: 28px;
            width: 120%;
        }
    }

}