.shop-item-card {
  position: relative;
  
  &:hover {
    .box-button-shop {
      top: 0%;
      visibility: visible;
      background-color: #00000046;
      opacity: 1;

      .css-3e8gmj-MuiButtonBase-root-MuiButton-root {
        visibility: visible;
        opacity: 0;
        transform: translateY(0%);
      }
    }
  }

  .box-button-shop {
    position: absolute;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    transition: all 0.3s linear;
    opacity: 0;

    .css-3e8gmj-MuiButtonBase-root-MuiButton-root {
      transform: translateY(-50%);
      visibility: hidden;
      opacity: 0;
      transition: all .5s linear ;
    }
  }
}


.hover-effect {
  overflow: hidden;
  transition: all .5s;
  
  .button-dinamyc-shop-page {
    display: flex;
    width: 100%;
    transition: all .5s;
    gap: 10px;
    
    div {
      &:first-child {
        flex: 1 0 100%;
        visibility: visible;
      }
  
      &:last-child {
        flex: 1 0 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

      }
    }
  }

  &:hover {
    .button-dinamyc-shop-page {
      transform: translateX(-100%);
    }
  }
}


.custom-paper-shop {
  position: relative;
  overflow: hidden;

  &.is-new::after {
    content: 'Exclusive';
    display: flex;
    justify-content: center;
    align-items: 'center';
    font-weight: 800;
    color: #fff;
    position: absolute;
    font-size: 16px;
    text-transform: uppercase;
    top: 10%;
    left: 25%;
    transform: rotateZ(45deg);
    width: 100%;
    background: radial-gradient(ellipse farthest-corner at right bottom, #FEDB37 0%, #FDB931 8%, #9f7928 30%, #8A6E2F 40%, transparent 80%),
    radial-gradient(ellipse farthest-corner at left top, #FFFFFF 0%, #FFFFAC 8%, #D1B464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  
    @media (min-width: 1450px) {
      top: 10%;
      left: 30%;
      font-size: 20px;
    }
  }
}    