@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);

.snip1493 {
  font-family: "Open Sans", Arial, sans-serif;
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 250px;
  max-width: 310px;
  width: 100%;
  background-color: #ffffff;
  color: #000000;
  text-align: left;
  font-size: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  .date {
    background-color: #00b8ff;
    top: 15px;
    color: #fff;
    right: 15px;
    min-height: 48px;
    min-width: 48px;
    position: absolute;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 50%;
    padding: 10px 0;

    span {
      display: block;
      line-height: 14px;
    }

    .month {
      font-size: 11px;
    }
  }

  figcaption {
    padding: 20px 20px 30px;
    background-color: #ffffff;
  }

  img {
    max-width: 100%;
    vertical-align: top;
    position: relative;
  }

  h3,p {
    margin: 0;
    padding: 0;
  }
}

.snip1493 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.snip1493:after {
  position: absolute;
  top: 12px;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 25px;
  border-color: transparent transparent transparent #00b8ff;
  content: "";
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}


.snip1493 h3 {
  margin-bottom: 10px;
  display: inline-block;
  font-weight: 700;
}

.snip1493 p {
  font-size: 0.8em;
  margin-bottom: 20px;
  line-height: 1.6em;
}

.snip1493 footer {
  padding: 0 25px;
  color: #999999;
  font-size: 0.8em;
  line-height: 50px;
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.snip1493 footer > div {
  display: inline-block;
  margin-right: 15px;
}

.snip1493 footer i {
  margin-right: 5px;
  font-size: 1.2em;
}

.snip1493 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  z-index: 1;
}

.snip1493:hover:after,
.snip1493.hover:after {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
