.button-active {
    display: flex;
    font-size: 10vw;
    color: transparent;

    span {
        animation: animate 5s linear infinite;
        font-weight: 800;
        
        &#letter-1 {
            animation-delay: calc(0.1s * 1);
        }
        &#letter-2 {
            animation-delay: calc(0.1s * 2);
        }
        &#letter-3 {
            animation-delay: calc(0.1s * 3);
        }
        &#letter-4 {
            animation-delay: calc(0.1s * 4);
        }
        &#letter-5 {
            animation-delay: calc(0.1s * 5);
        }
        &#letter-6 {
            animation-delay: calc(0.1s * 6);
        }
        &#letter-7 {
            animation-delay: calc(0.1s * 7);
        }
        &#letter-8 {
            animation-delay: calc(0.1s * 8);
        }
        &#letter-9 {
            animation-delay: calc(0.1s * 9);
        }
        &#letter-10 {
            animation-delay: calc(0.1s * 10);
        }
        &#letter-11 {
            animation-delay: calc(0.1s * 11);
        }
        &#letter-12 {
            animation-delay: calc(0.1s * 12);
        }
        &#letter-13 {
            animation-delay: calc(0.1s * 13);
        }
        &#letter-14 {
            animation-delay: calc(0.1s * 14);
        }
        &#letter-15 {
            animation-delay: calc(0.1s * 15);
        }
        &#letter-16 {
            animation-delay: calc(0.1s * 16);
        }
        &#letter-17 {
            animation-delay: calc(0.1s * 17);
        }
        &#letter-18 {
            animation-delay: calc(0.1s * 18);
        }
    }
}


@keyframes animate {
    0% {
        color: #13017b;
        filter: hue-rotate(0deg);

    }

    30%,
    70% {
        color: #00b8ff;
        filter: hue-rotate(360deg);
    }

    100% {
        color: #13017b;
        text-shadow: none;
        filter: hue-rotate(0deg);
    }
}